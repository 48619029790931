<template>
  <div style="text-align: -webkit-center">
    <template v-if="matter && !matter.use_matter_values">
      <DescriptorsContainer
        :ref_component="'EPMatter-Container'"
        :Title_text="'Programas'"
        :Text_info_tooltip="'Si se selecciona el predeterminado, se habilitará el enunciado del descriptor en otros Programas.'"
        :Title="egressProfileMatterDescriptor"
        :Descriptors="epmMiddleDescriptors"
        :allows_crud="allows_crud && matter && !matter.is_closed"
        :matter="matter"
        :Order="1"
        :VisibleOrder="false"
        :ProgramView="true"
        :show_add_button="false"
        :show_matter_descriptor="show_matter_descriptor"
        @updateOrder="updateOrderEpmMiddleDescriptors"
        @updateState="updateVisibleEpmMiddleDescriptors"
        @createDescription="createEpmMiddleDescriptors"
        @updateDescription="updateEpmMiddleDescriptors"
        @createTitle="createProfileMatterDescriptors"
        @updateTitle="updateProfileMatterDescriptors"
        @deleteDescriptors="deleteProfileMatterDescriptors"
        @deleteTitle="deleteTitle"
      >
      </DescriptorsContainer>
    </template>
    <template v-else>
      <DescriptorsContainer
        :ref_component="'Matter-Container'"
        :Title_text="'Programas'"
        :Text_info_tooltip="'Si se selecciona el predeterminado, se habilitará el enunciado del descriptor en otros Programas.'"
        :Title="egressProfileMatterDescriptor"
        :Descriptors="matterMiddleDescriptors"
        :allows_crud="allows_crud && oldMatter && !oldMatter.is_closed"
        :matter="matter"
        :Order="1"
        :VisibleOrder="false"
        :ProgramView="true"
        :show_add_button="false"
        :show_matter_descriptor="show_matter_descriptor"
        @updateOrder="updateOrderMatterMiddleDescriptors"
        @updateState="updateVisibleMatterMiddleDescriptors"
        @createDescription="createMatterMiddleDescriptors"
        @updateDescription="updateMatterMiddleDescriptors"
        @createTitle="createProfileMatterDescriptors"
        @updateTitle="updateProfileMatterDescriptors"
        @deleteDescriptors="deleteMatterMiddleDescriptors"
        @deleteTitle="deleteTitle"
      >
      </DescriptorsContainer>
    </template>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
export default {
  name: "MatterDescriptorsContainer",
  components: {
    DescriptorsContainer: () =>
      import("@/components/reusable/DescriptorsContainer"),
  },
  props: {
    oldMatter: {
      type: Object,
      required: true,
    },
    matter: {
      type: Object,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
    show_matter_descriptor: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      institution: "getInstitution",
      egress_profile_matter_descriptors:
        names.EGRESS_PROFILE_MATTER_DESCRIPTORS,
      epm_middle_descriptors: names.EPM_MIDDLE_DESCRIPTORS,
      m_middle_descriptors: names.M_MIDDLE_DESCRIPTORS,
    }),
    epmMiddleDescriptors() {
      return this.epm_middle_descriptors.filter(
        (x) => x.egress_profile_matter == this.matter.id
      );
    },
    matterMiddleDescriptors() {
      return this.m_middle_descriptors.filter(
        (x) => x.matter == this.oldMatter.id
      );
    },
    egressProfileMatterDescriptor() {
      if (this.matter && !this.matter.use_matter_values) {
        const epm_middle = this.epmMiddleDescriptors.map((x) => x.descriptor);
        if (
          (this.institution && this.institution.internal_use_id == "duoc_uc") ||
          this.$debug_change_duoc
        )
          return this.egress_profile_matter_descriptors.filter(
            (x) =>
              (x.is_default || epm_middle.includes(x.id)) &&
              (this.$equals(
                x.internal_use_id,
                "duoc_pmd_te_evaluation_situation"
              ) ||
                this.$equals(x.internal_use_id, "duoc_pmd_te_orientations") ||
                this.$equals(
                  x.internal_use_id,
                  "duoc_pmd_matter_description_superior"
                ) ||
                this.$equals(
                  x.internal_use_id,
                  "duoc_key_suggestions_and_warnings"
                ))
          );
        else
          return this.egress_profile_matter_descriptors.filter(
            (x) => x.is_default || epm_middle.includes(x.id)
          );
      } else {
        const matter_middle = this.matterMiddleDescriptors.map(
          (x) => x.descriptor
        );
        if (
          (this.institution && this.institution.internal_use_id == "duoc_uc") ||
          this.$debug_change_duoc
        )
          return this.egress_profile_matter_descriptors.filter(
            (x) =>
              (x.is_default || matter_middle.includes(x.id)) &&
              (this.$equals(
                x.internal_use_id,
                "duoc_pmd_te_evaluation_situation"
              ) ||
                this.$equals(x.internal_use_id, "duoc_pmd_te_orientations") ||
                this.$equals(
                  x.internal_use_id,
                  "duoc_pmd_matter_description_superior"
                ) ||
                this.$equals(
                  x.internal_use_id,
                  "duoc_key_suggestions_and_warnings"
                ))
          );
        else
          return this.egress_profile_matter_descriptors.filter(
            (x) => x.is_default || matter_middle.includes(x.id)
          );
      }
    },
  },
  methods: {
    updateOrderEpmMiddleDescriptors(item) {
      let order = 1;
      item.forEach((element) => {
        if (element.order != order) {
          element.order = order;
          if (
            !isNaN(element.id) &&
            this.epm_middle_descriptors.findIndex((x) => x.id == element.id) !=
              -1
          ) {
            let payload = {
              id: element.id,
              item: {
                order: element.order,
              },
            };
            this.$store.dispatch(names.PATCH_EPM_MIDDLE_DESCRIPTORS, payload);
          }
        }
        order += 1;
      });
    },
    updateVisibleEpmMiddleDescriptors(item) {
      if (!isNaN(item.id)) {
        let payload = {
          id: item.id,
          item: {
            is_visible: !item.is_visible,
          },
        };
        this.$store.dispatch(names.PATCH_EPM_MIDDLE_DESCRIPTORS, payload);
      }
    },
    createEpmMiddleDescriptors(text, object) {
      if (!isNaN(object.id) && !isNaN(this.matter.id)) {
        let item = {
          egress_profile_matter: this.matter.id,
          descriptor: object.id,
          description: text,
          order: 1 + this.epmMiddleDescriptors.length,
        };
        this.$store.dispatch(names.POST_EPM_MIDDLE_DESCRIPTORS, item);
      }
    },
    updateEpmMiddleDescriptors(object, text) {
      if (!isNaN(object.id)) {
        let payload = {
          id: object.id,
          item: {
            description: text,
          },
        };
        this.$store.dispatch(names.PATCH_EPM_MIDDLE_DESCRIPTORS, payload);
      }
    },
    createProfileMatterDescriptors(title) {
      if (isNaN(title.id)) {
        this.$store
          .dispatch(names.POST_EGRESS_PROFILE_MATTER_DESCRIPTORS, title)
          .then((response) => {
            if (this.matter && !this.matter.use_matter_values) {
              this.createEpmMiddleDescriptors("", response);
            } else {
              this.createMatterMiddleDescriptors("", response);
            }
          });
      }
    },
    updateProfileMatterDescriptors(title) {
      if (!isNaN(title.id)) {
        let payload = {
          id: title.id,
          item: {
            title: title.title,
            is_default: title.is_default,
          },
        };
        this.$store.dispatch(
          names.PATCH_EGRESS_PROFILE_MATTER_DESCRIPTORS,
          payload
        );
      }
    },
    deleteProfileMatterDescriptors(EpmMiddleDescriptor) {
      this.$swal({
        title:
          "¿Está seguro de que desea eliminar el contenido del título descriptivo?",
        text: `"Esta acción no se podrá revertir y podría afectar en otras ${this.$getVisibleNames(
          "mesh.egressprofilematter",
          true,
          "Asignaturas"
        )}."`,
        type: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(
            names.DELETE_EPM_MIDDLE_DESCRIPTORS,
            EpmMiddleDescriptor.id
          );
        }
      });
    },
    deleteTitle(object) {
      this.$store
        .dispatch(names.FETCH_M_MIDDLE_DESCRIPTORS, {
          descriptor_id: object.id,
        })
        .then((response1) => {
          this.$store
            .dispatch(names.FETCH_EPM_MIDDLE_DESCRIPTORS, {
              descriptor_id: object.id,
            })
            .then((response2) => {
              const response = response1.concat(response2);
              this.$swal({
                title:
                  "¿Está seguro de que desea eliminar el título descriptivo?",
                text: `Esta acción no se podrá revertir y se eliminarán los objetos referenciados en la parte inferior.`,
                type: "warning",
                footer: `<ul class="w-100" style="max-height: 300px; overflow-y: auto;">
            <li>Título: ${object.title}</li>
            ${response
              .map(
                (item) =>
                  `<li>contenido: ${this.$htmlToPlainText(
                    item.description
                  )}</li>`
              )
              .join("")}
              </ul>`,
                showCancelButton: true,
                cancelButtonText: "Cancelar",
              }).then((result) => {
                if (result.value) {
                  this.$store.dispatch(
                    names.DELETE_EGRESS_PROFILE_MATTER_DESCRIPTORS,
                    object.id
                  );
                }
              });
            });
        });
    },
    updateOrderMatterMiddleDescriptors(item) {
      let order = 1;
      item.forEach((element) => {
        if (element.order != order) {
          element.order = order;
          if (
            !isNaN(element.id) &&
            this.m_middle_descriptors.findIndex((x) => x.id == element.id) != -1
          ) {
            let payload = {
              id: element.id,
              item: {
                order: element.order,
              },
            };
            this.$store.dispatch(names.PATCH_M_MIDDLE_DESCRIPTORS, payload);
          }
        }
        order += 1;
      });
    },
    updateVisibleMatterMiddleDescriptors(item) {
      if (!isNaN(item.id)) {
        let payload = {
          id: item.id,
          item: {
            is_visible: !item.is_visible,
          },
        };
        this.$store.dispatch(names.PATCH_M_MIDDLE_DESCRIPTORS, payload);
      }
    },
    createMatterMiddleDescriptors(text, object) {
      if (!isNaN(object.id) && this.matter) {
        let item = {
          matter: this.matter.matter,
          descriptor: object.id,
          description: text,
          order: 1 + this.matterMiddleDescriptors.length,
        };
        this.$store.dispatch(names.POST_M_MIDDLE_DESCRIPTORS, item);
      }
    },
    updateMatterMiddleDescriptors(object, text) {
      if (!isNaN(object.id)) {
        let payload = {
          id: object.id,
          item: {
            description: text,
          },
        };
        this.$store.dispatch(names.PATCH_M_MIDDLE_DESCRIPTORS, payload);
      }
    },
    deleteMatterMiddleDescriptors(matterMiddleDescriptor) {
      this.$swal({
        title:
          "¿Está seguro de que desea eliminar el contenido del título descriptivo?",
        text: `"Esta acción no se podrá revertir y podría afectar en otras ${this.$getVisibleNames(
          "mesh.egressprofilematter",
          true,
          "Asignaturas"
        )}."`,
        type: "warning",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch(
            names.DELETE_M_MIDDLE_DESCRIPTORS,
            matterMiddleDescriptor.id
          );
        }
      });
    },
  },
  created() {
    if (this.matter && this.oldMatter) {
      this.$store.dispatch(names.FETCH_EGRESS_PROFILE_MATTER_DESCRIPTORS, {
        egress_profile_matter_id: this.matter.id,
      });
      this.$store.dispatch(names.FETCH_EPM_MIDDLE_DESCRIPTORS, {
        egress_profile_matter_id: this.matter.id,
      });
      this.$store.dispatch(names.FETCH_M_MIDDLE_DESCRIPTORS, {
        matter_id: this.oldMatter.id,
      });
    }
  },
};
</script>

<style scoped>
</style>

